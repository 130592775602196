import { User } from "../models/User";

export class LocalServiceStorage<T> {
	constructor(private keyName: string) { }

	public set(value: T): void;
	public set(value: string | number | boolean): void;
	public set(value: any): void {
		const today = new Date();
		const expiry = new Date(today.getTime() + (30 * 24 * 60 * 60 * 1000));
		const isPrimitive = ["string", "number", "boolean"].includes(typeof value);

		let dataToStore = isPrimitive ? value : JSON.stringify(value.toObject());

		localStorage.setItem(this.keyName, expiry.toISOString() + '::' + dataToStore);
	}

	public get(): T | null;
	public get(): string | number | boolean | null;
	public get(): any {
		const savedValue = localStorage.getItem(this.keyName);
		if (savedValue) {
			const [dateString, storedData] = savedValue.split('::');
			if ((new Date()).getTime() - (new Date(dateString)).getTime() <= 0) {
				try {
					const object = JSON.parse(storedData);
					const user = new User(new Date(object.birthday), object.lifeExpectancy);
					return user;
				} catch (e) {
					// Return as is for primitives
					return storedData;
				}
			}
			localStorage.removeItem(this.keyName);
		}
		return null;
	}
}