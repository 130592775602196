import { createContext } from 'react';
import { User } from '../models/User';

export const defaultUser: () => User = () => {
	return new User(new Date(), 0);
}

interface UserContextProps {
	isSaved: boolean;
	user: User;
	saveUser: () => void;
	setUser: (newUser: User) => void;
	setBirthDay: (newBirthDay: Date) => void;
}

export const UserContext = createContext<UserContextProps>({
	isSaved: false,
	user: defaultUser(),
	saveUser: () => { },
	setUser: () => { },
	setBirthDay: () => { },
});