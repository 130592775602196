export interface IUser {
	birthday: Date;
	estimatedEndDate: Date;
	lifeExpectancy: number;
	currentAge: number;
}

const milisecondsInYear = 365 * 24 * 60 * 60 * 1000;

export class User implements IUser {
	private _birthday: Date;
	private _estimatedEndDate: Date;
	public _lifeExpectancy: number;

	constructor(birthday: Date, lifeExpectancy: number) {
		this._birthday = birthday;
		this._estimatedEndDate = new Date(birthday.getTime() + lifeExpectancy * milisecondsInYear);
		this._lifeExpectancy = lifeExpectancy;
	}

	public get birthday(): Date {
		return this._birthday;
	}

	public set birthday(value: Date) {
		this._birthday = value;
		this._estimatedEndDate = new Date(value.getTime() + this.lifeExpectancy * milisecondsInYear);
	}

	public get lifeExpectancy(): number {
		return this._lifeExpectancy;
	}

	public set lifeExpectancy(value: number) {
		this._lifeExpectancy = value;
		this._estimatedEndDate = new Date(this.birthday.getTime() + value * milisecondsInYear);
	}

	public get estimatedEndDate (): Date {
		return this._estimatedEndDate;
	}

	public get currentAge(): number {
		return this.ageAt(new Date());
	}

	public ageAt(comparisonDate: Date): number {
		return Math.floor((comparisonDate.getTime() - this.birthday.getTime()) / milisecondsInYear);
	}

	public toObject() {
		return {
			birthday: this.birthday,
			lifeExpectancy: this.lifeExpectancy,
		}
	}
}