import './App.css';
import { FunctionComponent, lazy, Suspense, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline, Container } from '@material-ui/core';
import { defaultUser, UserContext } from '../../context';
import { userStorage } from '../../localStorageService/user';
import { User } from '../../models/User';

const Home = lazy(() => import('../pages/home/Home'));
const UserDetails = lazy(() => import('../pages/user-details/user-details'));

const App: FunctionComponent<{}> = () => {
	const storedUser = userStorage.get();
	const [isSaved, setIsSaved] = useState<boolean>(!!storedUser);
	const [user, setUser] = useState<User>(storedUser || defaultUser());

	const saveUser = () => {
		const lifeExpectancy = getLifeExpectancy(user);
		user.lifeExpectancy = lifeExpectancy;
		setUser(user);
		userStorage.set(user);
		setIsSaved(true);
	}

	const setBirthDayLocal = (newBirthDay: Date) => {
		user.birthday = newBirthDay;
		setUser(user);
	}

	return (
		<BrowserRouter>
			<UserContext.Provider value={{ user: user, isSaved: isSaved, saveUser, setUser: setUser, setBirthDay: setBirthDayLocal }}>
				<CssBaseline />
				<Container component='div' maxWidth="lg">
					<Suspense fallback={<div>Loading component...</div>}>
						<Switch>
							<Route path="/" exact component={Home} />
							<Route path="/user-details" exact component={UserDetails} />
							<Route path="/" component={NotFound} />
						</Switch>
					</Suspense>
				</Container>
			</UserContext.Provider>
		</BrowserRouter>
	);
}

export default App;

const getLifeExpectancy = (user: User): number => {
	return 81;
}

const NotFound: React.FunctionComponent<{}> = () => {
	return <div>Not found</div>;
}